import * as React from 'react';
import { ICoreContext, getAsset } from '@msdyn365-commerce/core';
import { Button, Modal, ModalBody, ModalFooter } from '@msdyn365-commerce-modules/utilities';
import type { CameraDevice, Html5Qrcode } from 'html5-qrcode';

const qrcodeRegionId = "html5qr-code-full-region";
let cachedModule: typeof import('html5-qrcode') | undefined;

async function getScannerModule() {
    if (!cachedModule) {
        cachedModule = await import('html5-qrcode');
    }
    return cachedModule;
}

export interface IBarcodeScannerResources {
    noCameraDevicesFound?: string;
    errorGettingDevices?: string;
    loading?: string;
    chooseCamera?: string;
    changeCamera?: string;
    close?: string;
}

export interface IBarcodeScannerProps {
    context: ICoreContext;
    modalIsOpen: boolean;
    onClose: () => void;
    onFound: (text: string) => void;
    resources?: IBarcodeScannerResources;
}

const DefaultResources: IBarcodeScannerResources = {
    noCameraDevicesFound: 'No camera devices found',
    errorGettingDevices: 'Error getting devices',
    loading: 'Loading...',
    chooseCamera: 'Choose camera to use:',
    changeCamera: 'Change device',
    close: 'Close'
};

const BarcodeScanner = (props: IBarcodeScannerProps) => {
    const { context, modalIsOpen } = props;
    const resources = { ...DefaultResources, ...props.resources };
    const [devices, setDevices] = React.useState<CameraDevice[] | undefined>(undefined);
    const [working, setWorking] = React.useState(true);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [selectedDeviceId, setSelectedDeviceId] = React.useState<string | undefined>(undefined);

    const scanner = React.useRef<Html5Qrcode | null>(null);

    React.useEffect(() => {
        if (!modalIsOpen) {
            if (scanner.current?.isScanning) {
                scanner.current?.stop().then(() => {
                    scanner.current?.clear();
                });
            }
            setWorking(true);
            setError(undefined);
            setSelectedDeviceId(undefined);
            return;
        }

        if (devices) {
            setWorking(false);
            return;
        }

        // Get all devices
        const work = async () => {
            const { Html5Qrcode } = await getScannerModule();

            try {
                const devices = await Html5Qrcode.getCameras();
                setDevices(devices.length > 0 ? devices : undefined);

                if (devices.length === 0) {
                    setError(resources.noCameraDevicesFound);
                } else if (devices.length === 1) {
                    setSelectedDeviceId(devices[0].id);
                }
            } catch (error) {
                console.error("Error getting devices", error);
                setError(resources.errorGettingDevices);
            } finally {
                setWorking(false);
            }
        };
        void work();
    }, [modalIsOpen]);

    React.useEffect(() => {
        if (!selectedDeviceId) {
            if (scanner.current?.isScanning) {
                scanner.current?.stop();
            }
            return;
        }

        const work = async () => {
            const { Html5Qrcode } = await getScannerModule();

            scanner.current = new Html5Qrcode(qrcodeRegionId);
            void scanner.current.start(selectedDeviceId, {
                fps: 10,
                // qrbox: 250
                qrbox: (viewFinderWidth, viewFinderHeight) => {
                    const minEdgePercentage = 0.7;
                    const minEdgeSize = Math.min(viewFinderWidth, viewFinderHeight);
                    const qrboxSize = Math.min(Math.floor(minEdgeSize * minEdgePercentage), 250);
                    return {
                        width: qrboxSize,
                        height: qrboxSize
                    };
                }
            }, onScanSuccess, undefined);
        };
        void work();
    }, [selectedDeviceId]);

    const onScanSuccess = (decodedText: string) => {
        // Call the callback
        props.onFound(decodedText);
    };

    const baseClassName = 'vh-barcode-scanner';
    const shouldShowScanner = !working && !error && devices && selectedDeviceId;

    return (
        <Modal modalClassName={`${baseClassName}__wrapper`} className='msc-modal-input-required vh-barcode-scanner' isOpen={modalIsOpen} toggle={props.onClose}>
            <ModalBody className={shouldShowScanner ? 'center' : undefined}>
                <div style={{ display: working ? 'block' : 'none' }}>{resources.loading}</div>

                <div style={{ display: error ? 'block' : 'none' }}>{error}</div>

                <div style={{ display: !working && !error && devices && !selectedDeviceId ? 'block' : 'none' }}>
                    <div className={`${baseClassName}__heading`}>{resources.chooseCamera}</div>
                    <ul className={`${baseClassName}__device-list unstyled`}>
                        {devices?.map((device, index) => (
                            <li key={index}>
                                <Button onClick={() => setSelectedDeviceId(device.id)}>
                                    <span>{device.label}</span>
                                    <img src={getAsset('icons/arrow-right.svg', context.request)} height={32} width={20.8} />
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div style={{ display: shouldShowScanner ? 'block' : 'none', position: 'relative', width: '100%' }}>
                    <div id={qrcodeRegionId} style={{ width: '100%' }} />
                    {/* <div style={{
                        position: 'absolute',
                        width: 250,
                        height: 250,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)' }}
                    /> */}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    onScanSuccess('Test');
                }}>Test</Button>
                <Button
                    className={`${baseClassName}__switch-device-btn`}
                    onClick={() => setSelectedDeviceId(undefined)}
                    style={{ display: selectedDeviceId && (devices?.length || 0) > 1 ? 'block' : 'none' }}
                >
                    {resources.changeCamera}
                </Button>
                <Button className={`${baseClassName}__cancel-btn`} onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    props.onClose();
                }}>
                    {resources.close}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default BarcodeScanner;
